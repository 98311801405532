import React from 'react';
import logo_NY from './../src/components/container/bilder/loga_NY.png';
import './App.css';
import NavBar from "../src/components/navBar/navBar";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Contacto from './components/contacto/contacto';
import ProductListing from './components/productListing/productListing';
import CategoryListing from './components/categoryListing/categoryListing';
import Product from './components/product/product';
import Home from "./components/home/home";
import Footer from './components/footer/footer';
import AvisoLegal from "./components/avisoLegal/avisoLegal";
import CookiesPage from "./components/cookiesPage/cookiesPage";
import SobreNosotros from "./components/sobreNosotros/sobreNosotros";
import GaleriaListing from "./components/galeriaListing/galeriaListing";
import Compras from "./components/compras/compras";
import Cart from "./components/cart/cart";
import Checkout from "./components/checkout/checkout";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import {Form, Input} from 'reactstrap';
import 'react-statusbar/dist/statusbar.css';
import { Statusbar } from 'react-statusbar';

class App extends React.Component {
  
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  
  constructor(props) {
    super(props);

    this.addCartItem = this.addCartItem.bind(this);
    this.removeCartItem = this.removeCartItem.bind(this);
    this.emptyCart = this.emptyCart.bind(this);
    this.flushCart = this.flushCart.bind(this);
    this.searchMe = this.searchMe.bind(this);
    this.formPreventDefault = this.formPreventDefault.bind(this);
    this.updateSearchInput = this.updateSearchInput.bind(this);
 
    this.state = {
      cookieJar: this.props.cookies.get("vegabonty") || [],
      cartTotalAmount: 0,
      searchCriteria: ""
    };
  }

  componentDidMount() {
    if (this.state.cookieJar === 'undefined' || !Array.isArray(this.state.cookieJar)) {
      this.setDefaultCookie();
    }

    this.updateCartTotal();
  }

  setDefaultCookie() {
    this.emptyCart();
  }

  emptyCart() {
    const { cookies } = this.props; 
    cookies.set('vegabonty', [], { path: '/' });
    this.setState({cookieJar: cookies.get("vegabonty")});
  }

  async flushCart() {
    const { cookies } = this.props; 
    await cookies.set('vegabonty', [], { path: '/' });
    this.setState({cookieJar: cookies.get("vegabonty")});
  }

  updateCookie() {
    const { cookies } = this.props;
    cookies.set("vegabonty", this.state.cookieJar.filter((item) => Number(item.amount) > 0), { path: "/" });    
    this.updateCartTotal();
    this.setState({cookieJar: cookies.get("vegabonty")});
  }

  updateCartTotal() {
    let productCount = 0;
    
    for (var i=0; i<this.state.cookieJar.length; i++) {
      if (Number(this.state.cookieJar[i].amount)>0) {
        productCount = productCount + this.state.cookieJar[i].amount;
      }
    }

    if (productCount>0) {
      this.setState({cartTotalAmount: productCount});    
    }
  }

  addCartItem (productId, productSize, amount) {
     
    let tmpArr = this.state.cookieJar;
    if (productId==='undefined' || !productId) {
      return false;
    }

    let match=false;
    for (var i=0; i<tmpArr.length; i++) {
      if (parseInt(tmpArr[i].id) === parseInt(productId) && parseInt(tmpArr[i].size) === parseInt(productSize)) {
        tmpArr[i].amount=Number(tmpArr[i].amount)+Number(amount);
        tmpArr[i].size=productSize;
        match=true;
      }
    }

    if (match === false) {
      tmpArr.push({id:productId,amount:amount,size:productSize})
    }

    tmpArr = tmpArr.filter(item => Number(item.amount)>0);
    
    this.setState({cookieJar: tmpArr});
    this.updateCookie();
  }

removeCartItem (productId, sizeValue) {
  // Initiera cookies
  const { cookies } = this.props;
  let tmpArr = this.state.cookieJar;
  let tmpPos = 0;
    if (productId==='undefined' || !productId) {
      return false;
    }
    // Loopa igenom cartNow och hitta matchande produkter
    let match=false;
    for (var i=0; i<tmpArr.length; i++) {
      if (parseInt(tmpArr[i].id) === parseInt(productId) && parseInt(tmpArr[i].size) === parseInt(sizeValue)) {
        tmpArr[i].amount--;
        tmpPos = i;
        match=true;
      }
    }

    tmpArr = tmpArr.filter(item => Number(item.amount)>0);

    cookies.set("vegabonty", tmpArr, { path: "/" });
    this.setState({cookieJar: tmpArr});    
    this.updateCartTotal();
    this.updateCookie();
}

formPreventDefault(e) {
  e.preventDefault();
}

updateSearchInput(event){
  this.setState({searchCriteria : event.target.value})
}

searchMe(evt) {
  evt.preventDefault();
  window.location.href = "/nuestrosdulces/buscar/" + this.state.searchCriteria;
}

render() {
 
  return (
     <div className="App">   

     <div className="statusbar">AVISO IMPORTANTE: Queridos vegabonters, debido al excesivo número de pedidos no estamos aceptando mas pedidos en la web hasta el 23/01/2023. Si quieres realizar un pedido puedes contactar con nosotros al 644697064</div>

        <header className="App-header">
          <div className="container">
                        <div className="row">
              <a href="/"><div className="col-md-12"><img src={logo_NY} className="App-logo" alt="logo" /></div></a> 
            </div>              
            
          </div>     
          <div className="form">
            <Form id="searchForm" inline onSubmit={this.formPreventDefault}>
                <Input type="text" value={this.state.searchCriteria} onChange={this.updateSearchInput} name="searchCriteria" id="searchCriteria" placeholder="Donuts" className="mr-sm-2" />
                <button className="buscar" onClick={(evt)=>this.searchMe(evt)}>Buscar</button>
            </Form>
          </div> 
          <NavBar cartTotalAmount={this.state.cartTotalAmount} cookieJar={this.state.cookieJar}></NavBar>   
        </header>
        
      <div className="main-container">
        <Router>        
          <Switch>
              <Route path="/producto/:productId" render={props => <Product addCartItem={this.addCartItem} {...props} />} />
              <Route path="/nuestrosdulces/:categoryName/:categoryId" component={ProductListing} />
              <Route path="/nuestrosdulces" component={CategoryListing} />
              <Route path="/contacto" component={Contacto} /> 
              <Route path="/avisolegal" component={AvisoLegal} />
              <Route path="/cookies" component={CookiesPage}/>
              <Route path="/sobrenosotros" component={SobreNosotros}/>
              <Route path="/galeria" component={GaleriaListing}/>
              <Route path="/compras" component={Compras}/>
              <Route path="/checkout" render={props => <Checkout flushCart={this.flushCart} emptyCart={this.emptyCart} addCartItem={this.addCartItem} removeCartItem={this.removeCartItem} cartData={this.state.cookieJar} {...props} />} />
              <Route path="/carrito" render={props => <Cart emptyCart={this.emptyCart} addCartItem={this.addCartItem} removeCartItem={this.removeCartItem} cartData={this.state.cookieJar} {...props} />} />
              <Route path="/" component={Home} />            
          </Switch>      
        </Router>
      </div>
      <footer className="footerHome">
        <Footer></Footer>
      </footer>
    </div>
  );

}
}
export default withCookies(App);